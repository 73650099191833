import React, { FC, useMemo, useRef, useState } from 'react';
import ChevronLeft from '../../../assets/icon/ChevronLeft';
import ChevronRight from '../../../assets/icon/ChevronRight';
import Image from '../../../interfaces/image.interface';
import { Questions } from '../../../interfaces/question.interface';
import Accordion from '../accordion/accordion';
import * as styles from './faq.module.scss';

type RTFaqProps = {
  items: Questions[];
  faqTitle: string;
  faqImage: Image[];
  bgImage?: string;
  textColor: string;
  mainColor: string;
  isSkewed?: boolean;
};

const RTFaq: FC<RTFaqProps> = ({ isSkewed = false, ...entry }) => {
  const CHUNK = 7;

  const [currentPage, setCurrentPage] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const questionsByChunk = useMemo(() => {
    return entry.items.map((_, i, arr) => (i % CHUNK ? [] : [arr.slice(i, i + CHUNK)])).flat();
  }, [entry.items]);

  const canPrev = useMemo(() => {
    if (currentPage === 0) return false;
    return true;
  }, [currentPage]);

  const canNext = useMemo(() => {
    if (currentPage + 1 === questionsByChunk.length) return false;
    return true;
  }, [currentPage, questionsByChunk.length]);

  const scrollIntoView = () => {
    wrapperRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  };

  const prev = () => {
    setCurrentPage(page => page - 1);
    scrollIntoView();
  };

  const next = () => {
    setCurrentPage(page => page + 1);
    scrollIntoView();
  };

  return (
    <section
      id='faq'
      ref={wrapperRef}
      className='py-16 xl:py-24 bg-no-repeat bg-center bg-cover bg-fixed bg-primary'
      style={entry.bgImage ? { backgroundImage: entry.bgImage } : {}}
    >
      <div className='container text-white'>
        <div className='lg:flex lg:space-x-16'>
          <div className='hidden lg:block w-4/12'>
            <div className='aspect-w-4 aspect-h-10'>
              <img src={entry.faqImage[0].url} alt='faq' className='object-cover rounded-2xl' />
            </div>
          </div>
          <div className='w-full lg:w-8/12 space-y-16'>
            <h3 className={`${styles.title} lg:mt-8`} style={{ color: entry.textColor || '#fff' }}>
              {entry.faqTitle}
              <div className={styles.titleBar} style={{ backgroundColor: entry.mainColor }} />
            </h3>
            <div>
              {questionsByChunk[currentPage].map((q, index) => (
                <Accordion key={index} title={q.question} content={q.response} color={entry.textColor || '#ffffff'} />
              ))}
            </div>
            {questionsByChunk.length > 1 && (
              <div className='flex justify-end'>
                <div className='flex space-x-4'>
                  <button onClick={canPrev ? prev : undefined} className={canPrev ? '' : 'opacity-20 cursor-not-allowed'}>
                    <ChevronLeft height={24} width={24} fill={entry.textColor || '#fff'} />
                  </button>
                  <p className='text-xl' style={{ color: entry.textColor || '#fff' }}>
                    {currentPage + 1} / {questionsByChunk.length}
                  </p>
                  <button onClick={canNext ? next : undefined} className={canNext ? '' : 'opacity-20 cursor-not-allowed'}>
                    <ChevronRight height={24} width={24} fill={entry.textColor || '#fff'} />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RTFaq;
