import React, { FC, useRef, useState } from 'react';
import useSSRLayoutEffect from '../../../hooks/useSSRLayoutEffect';
import * as styles from './accordion.module.scss';

type AccordionProps = {
  title: string;
  content: string;
  color: string;
};

const Accordion: FC<AccordionProps> = ({ title, content, color }) => {
  const accordionContentRef = useRef<HTMLDivElement>(null);
  const [accordionContentHeight, setAccordionContentHeight] = useState(0);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const openAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const initAccordionHeight = () => {
    if (accordionContentRef.current) {
      setAccordionContentHeight(accordionContentRef.current?.clientHeight);
    }
  };

  useSSRLayoutEffect(() => {
    initAccordionHeight();
    document.addEventListener('resize', initAccordionHeight);

    return () => {
      document.removeEventListener('resize', initAccordionHeight);
      setAccordionContentHeight(0);
    };
  }, []);

  return (
    <div className='relative w-full' style={{ color: color }}>
      <p onClick={openAccordion} className={`block font-bold text-lg py-8 pr-12 cursor-pointer font-MarkSCPro`}>
        {title}
      </p>
      <div
        className='relative overflow-hidden'
        style={{ height: isAccordionOpen ? accordionContentHeight : 0, transition: 'height 0.6s cubic-bezier(0.9, 0, 0.25, 1)' }}
      >
        <p ref={accordionContentRef} className='absolute pb-4 md:pb-8 pr-6 lg:pr-12'>
          {content}
        </p>
      </div>
      <hr style={{ borderColor: color }} />
      <div onClick={openAccordion} className={`${styles.cross} cursor-pointer ${isAccordionOpen ? styles.crossOpen : ''}`}>
        <span className={styles.crossTop} style={{ backgroundColor: color }} />
        <span className={styles.crossBottom} style={{ backgroundColor: color }} />
      </div>
    </div>
  );
};

export default Accordion;
